import { GetStaticProps } from 'next/types'
import { cmsApi } from '#cmsApi'
import { BannerAttributes } from '#cmsApi/banner'
import { HomePageAttributes } from '#cmsApi/homePage'
import { BannerMessage } from '#components/Banner'
import { Layout } from '#components/layout'
import { FullPageError, Home } from '#components/views'
import { config } from '#config'
import { generateOpenGraphData, OpenGraphData } from '#lib/seo'

type Props = {
  bannerContent: BannerAttributes | null
  homePageContent: HomePageAttributes | null
}

const HomePage = ({ bannerContent, homePageContent }: Props) => {
  if (!homePageContent) {
    return <FullPageError errMessage="Missing data in home page" />
  }

  const bannerMessage: BannerMessage | undefined = bannerContent?.isVisible
    ? {
        id: bannerContent.messageId,
        markdown: bannerContent.message,
      }
    : undefined

  return (
    <Layout>
      <Home bannerMessage={bannerMessage} {...homePageContent} />
    </Layout>
  )
}

export const getStaticProps: GetStaticProps<
  Props & { openGraphData: OpenGraphData[] }
> = async () => {
  const [bannerResponse, homePageResponse] = await Promise.all([
    cmsApi.getBanner(),
    cmsApi.getHomePage(),
  ])

  return {
    props: {
      bannerContent: bannerResponse.data?.attributes || null,
      homePageContent: homePageResponse.data?.attributes || null,
      openGraphData: generateOpenGraphData({
        // pageTitle: Use default title of "Net Purpose"
        pageDescription: homePageResponse.data?.attributes.titleSection.title,
        shareImageUrl:
          'https://storage.googleapis.com/np-web-cms-assets/Share%20images/home-page-share.jpg',
      }),
    },
    revalidate: config.revalidationDelayInSeconds,
  }
}

export default HomePage
